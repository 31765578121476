import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Button,
  Card,
  TextField,
  CircularProgress,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ChatOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

import PrimaryModal from '../../components/PrimaryModal';
import CustomSnackbar from '../../components/Snackbar';
import Buttons from '../../components/Buttons';

import { ReactComponent as LogoEmpresa } from '../../assets/somapay_digital_bank.svg';
import { ReactComponent as LoginTitle } from '../../assets/loginTitle2.svg';
import styles from './styles/style.module.css';

import useLogin from './Hooks/useLogin';

const Login = ({ hideAlert, showAlert = false, alertMessage = '' }) => {
  const {
    handleChange,
    handleErrorMessages,
    handleSubmit,
    handleOpenChat,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmitRequestValidation,
    values,
    onChangeCodeRequest,
    onResetPasswordButtonClicked,
    currentTime,
    state: {
      blockedUser,
      setBlockedUser,
      errorDetail,
      errorStatus,
      showCodeRequest,
      emailNotReceived,
      countdownHasStarted,
      isSnackbarActive,
      snackbarMessage,
      codeRequest,
    },
  } = useLogin(hideAlert);

  return (
    <div>
      <div className={styles.pageContainer}>
        <Grid container className={styles.mainContent} spacing={-4}>
          <CssBaseline />
          <div className={styles.informationContent}>
            <LoginTitle className={styles.loginTitle} />
            <div>
              <div>
                <Typography className={styles.subtitle}>
                  Facilidade, agilidade e segurança para quem paga e para quem
                  recebe.
                </Typography>
              </div>
            </div>
          </div>
          <Card className={styles.card}>
            <div className={styles.paper}>
              {showAlert && (
                <Alert severity="warning" className={styles.alert}>
                  {alertMessage}
                </Alert>
              )}
              <div className={styles.paperContent}>
                <LogoEmpresa className={styles.logoEmpresa} />
                <form noValidate className={styles.form}>
                  <TextField
                    error={values.errorCpfCnpj}
                    helperText={
                      values.errorCpfCnpj ? values.errorMessageCpfCnpj : ''
                    }
                    value={values.cpfEmail}
                    disabled={values.disabledCpfEmail}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="emailCpf"
                    label="Email ou CPF"
                    name="emailCpf"
                    autoComplete="email"
                    className={styles.input}
                    onChange={handleChange('cpfEmail')}
                    autoFocus
                    inputProps={{
                      autoCapitalize: 'none',
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    disabled={values.disabledPassword}
                    className={styles.inputPassword}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      style={{
                        color: values.errorPassword
                          ? '#f44336'
                          : 'rgba(0, 0, 0, 0.54)',
                      }}
                    >
                      Senha
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                      error={values.errorPassword}
                      inputProps={{
                        autoCapitalize: 'none',
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={50}
                    />
                  </FormControl>

                  {showCodeRequest && (
                    <div>
                      <Typography style={{ marginTop: 20, marginBottom: -20 }}>
                        {`Informe o código que você recebeu por ${
                          emailNotReceived ? 'SMS' : 'e-mail'
                        }.`}
                      </Typography>
                      <TextField
                        value={codeRequest}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="code"
                        label="Código"
                        name="code"
                        className={styles.input}
                        onChange={event => onChangeCodeRequest(event)}
                        autoFocus
                        inputProps={{
                          autoCapitalize: 'none',
                        }}
                      />
                      <Button
                        fullWidth
                        color="primary"
                        onClick={e => handleSubmitRequestValidation(e, true)}
                        disabled={countdownHasStarted}
                      >
                        {countdownHasStarted
                          ? `Enviar código por SMS em: ${currentTime}`
                          : 'Enviar código por SMS'}
                      </Button>
                    </div>
                  )}

                  {values.errorPassword && (
                    <Typography className={styles.passwordError}>
                      {handleErrorMessages(
                        errorStatus,
                        errorDetail,
                        values.password,
                      )}
                    </Typography>
                  )}

                  <div />
                  <div className={styles.submitArea}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={styles.submit}
                      onClick={e =>
                        showCodeRequest
                          ? handleSubmit(e)
                          : handleSubmitRequestValidation(e, false)
                      }
                      disabled={values.disabled}
                    >
                      {values.loading ? (
                        <div className={styles.circularProgress}>
                          <CircularProgress
                            className={styles.spinner}
                            size={styles.spinner}
                          />
                        </div>
                      ) : (
                        'ACESSAR'
                      )}
                    </Button>
                    <Button
                      fullWidth
                      color="primary"
                      onClick={onResetPasswordButtonClicked}
                    >
                      Esqueci minha senha
                    </Button>

                    <Buttons.PrimaryButton
                      fullWidth
                      variant="text"
                      title="Fale com a gente"
                      onClick={handleOpenChat}
                      icon={<ChatOutlined color="primary" />}
                    />
                  </div>
                </form>
              </div>
            </div>
          </Card>
        </Grid>

        <PrimaryModal
          open={blockedUser}
          title="Acesso bloqueado."
          text="Seu usuário não tem acesso a nenhuma empresa ativa na plataforma."
          confirmButtonText="Entendi"
          onConfirmClicked={() => setBlockedUser(false)}
        />

        <CustomSnackbar
          open={isSnackbarActive}
          severity="success"
          message={snackbarMessage}
        />
      </div>
    </div>
  );
};

export default Login;
