import React from 'react';

import { getPixDisplayName, getTypeIcon, formatPixKey, unformatPixKey } from '../utils'

import {
  Box,
  Grid,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';

import brazilianCurrencyFormatter2 from '../../../../utils/brazilianCurrencyFormatter2';

import DropDown from '../../../../components/DropDown';

import styles from './styles.module.css';

export default function Form({
  pixValue,
  notes,
  handleChangePixValue,
  handleChangeNotes,
  INPUT_PIX_VALUE_VALIDATE,
  loading,
  pixKeys,
  isCelcoin,
  goToRegisterNewPix,
  handleSelectPix
}) {
  
  const renderPixOption = (option) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div 
        style={{ 
          marginRight: 16,  
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8,
          borderRadius: 20,
          backgroundColor: '#52197F1A'
        }}>
          {getTypeIcon(option.type)}
      </div>
      <Box>
        <Typography style={{ color: '#666666', fontSize: 12 }}>
          {getPixDisplayName(option.type)}
        </Typography>
        <Typography style={{ color: '#2E2E2E', fontSize: 12 }}>
          {formatPixKey(option)}
        </Typography>
      </Box>
    </div>
  );

  return (
    <>
      <Box mb={3}>
        <Typography className={styles.biggerText}>
          Insira as informações abaixo para gerar o seu QR Code:
        </Typography>

        <Box mt={0.5} />

        <Typography className={styles.defaultText}>
          Você pode transferir um valor entre <span>R$20,00</span> e{' '}
          <span>R$9.000.000,00</span>.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            placeholder="Valor do Pix*"
            value={brazilianCurrencyFormatter2(pixValue)}
            onChange={handleChangePixValue}
            InputProps={{
              maxLength: 15,
              minLength: 8,
            }}
            disabled={loading}
            className={styles.valueBilletInput}
            fullWidth
            label="Valor do Pix*"
            helperText={
              <FormHelperText
                className={
                  INPUT_PIX_VALUE_VALIDATE ? styles.redHelperText : styles.helperText
                }
              >
                {INPUT_PIX_VALUE_VALIDATE
                  ? 'Digite um valor entre R$ 20,00 e R$ 9.000.000,00'
                  : '*Obrigatório'}
              </FormHelperText>
            }
          />
        </Grid>
        {!isCelcoin && (
          <Grid item xs={12} sm={6}>
            <DropDown
                options={pixKeys}
                onSelectOption={handleSelectPix}
                onRegisterNewItem={goToRegisterNewPix}
                renderOption={renderPixOption}
                placeholder="Chave Pix"
                noOptionsText="Nenhuma chave cadastrada"
                helperText={!isCelcoin && '*Obrigatório'}
                placeholderSearchInput="Pesquisar chave"
                disabled={isCelcoin}
            />
          </Grid>
        )}
      </Grid>

      <Grid container wrap="wrap" style={{marginTop:16}}>
        <TextField
          variant="outlined"
          placeholder="Observações"
          label="Observações"
          fullWidth
          value={notes}
          onChange={handleChangeNotes}
          disabled={loading}
        />
      </Grid>
    </>
  );
}
