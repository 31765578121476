import React from 'react';
import { Box } from '@material-ui/core';

import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';
import BottomBar from '../../../components/BottomBar';
import { PayrollPreviewCard } from './components/PayrollPreviewCard';
import { CardLoading } from './components/CardLoading';
import { PayrollModals } from '../components/PayrollModals';
import Form from '../../../components/Form';

import { useMultipleFilesUploadReview } from './hooks/useMultipleFilesUploadReview';

export default function PayrollFilesConfirmScreen() {
  const { batches, bottomBar } = useMultipleFilesUploadReview();

  return (
    <>
      <Header
        title="Confira os dados antes de autorizar"
        subtitle="Selecione as empresas e suas respectivas folhas que deseja autorizar"
      />

      <Form.Checkbox
        label="Selecionar todas as folhas"
        name="selectAll"
        onChange={batches.onSelectAllPayrolls}
        checked={batches.allFilesSelected}
        disabled={!batches.batchList.length}
        indeterminate={batches.allFilesIndeterminate}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {batches?.isLoading ? (
          <CardLoading />
        ) : (
          batches?.batchList?.map(batch => (
            <PayrollPreviewCard
              key={batch?.key}
              payroll={batch}
              onSelect={() => batches.onSelectAll(batch?.companyName)}
            />
          ))
        )}
      </Box>

      {batches.type === 'fileQuery' && (
        <Buttons.SecondaryButton
          onClick={bottomBar.onBack}
          color="primary"
          title="Voltar"
          variant="outlined"
        />
      )}

      <BottomBar>
        {batches.type === 'fileQuery' && (
          <Buttons.SecondaryButton
            onClick={bottomBar.onBack}
            color="primary"
            title="Voltar"
            variant="outlined"
          />
        )}
        <Buttons.PrimaryButton
          color="secondary"
          title="Autorizar"
          loading={bottomBar.isLoading}
          disabled={!bottomBar.hasSelectedAnyNsu}
          onClick={bottomBar.onSubmit}
        />
      </BottomBar>
      <PayrollModals />
    </>
  );
}
